import React from 'react'
// import moment from 'moment'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import {
  Layout,
  Helmet,
  Section,
  Container,
  Slider,
  GoogleMap,
  ContactForm,
} from 'components'

const CommercialTemplate = ({
  data: { contentfulListingCommercial },
  location,
}) => {
  let {
    commercialListingName,

    commercialListingphotos,
    commercialListingphotosModal,
    photosInteriors,
    photosInteriorsModal,

    commercialListingSecteur,
    commercialListingAvailability,
    commercialListingStories,
    commercialListingSize,

    commercialSpecs,
    commercialListingNearby,
    googleMapCommercial,
    commercialListinglocauxLouer,

    commercialListingAgent,
  } = contentfulListingCommercial
  return (
    <Layout className="item-page">
      <Helmet
        title="Nomade, gestion immobilière"
        description="Notre mission, assurer la qualité de vie de nos locataires, dans nos immeubles résidentiels, en offrant des espaces locatifs novateurs et un service-client exemplaire."
        url={location.pathname}
      />
      <Section>
        <Container>
          <h1 className="red-line">{commercialListingName}</h1>
          <div className="flex images">
            {commercialListingphotos && (
              <Slider images={commercialListingphotos} imagesModal={commercialListingphotosModal}/>
            )}
            {photosInteriors && <Slider images={photosInteriors} imagesModal={photosInteriorsModal} />}
          </div>
          <div className="details">
            <div>
              <h1>Informations</h1>
              <ul>
                {commercialListingSecteur && (
                  <li>
                    <b>Secteur: </b>
                    {commercialListingSecteur}
                  </li>
                )}
                {commercialListingSize && (
                  <li>
                    <b>Superficie disponible: </b>
                    {commercialListingSize}
                  </li>
                )}
                {commercialListingAvailability && (
                  <li>
                    <b>Disponibilité: </b>
                    {commercialListingAvailability}
                  </li>
                )}
                {commercialListingStories && (
                  <li>
                    <b>Nombre d’étage: </b>
                    {commercialListingStories}
                  </li>
                )}
              </ul>
            </div>
            <div>
              <h1>Caractéristiques</h1>
              <ul>
                {commercialSpecs &&
                  commercialSpecs.map(item => <li key={item}>{item}</li>)}
              </ul>
            </div>
            <div className="flex-reverse-mobile">
              <div className="map-object">
                <GoogleMap coords={googleMapCommercial} />
              </div>
              <div>
                <h1>À proximité</h1>
                <ul>
                  {commercialListingNearby &&
                    commercialListingNearby.map(item => (
                      <li key={item}>{item}</li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div>
            <h1>Locaux à louer</h1>
            {commercialListinglocauxLouer && (
              <Slider images={commercialListinglocauxLouer} imagesModal={commercialListinglocauxLouer}plans />
            )}
          </div>
        </Container>
      </Section>
      <Section red className="consultants-section">
        <Container>
          <h1>Vous désirez visiter ou louer un local ?</h1>
          <div className="flex consultants">
            {commercialListingAgent &&
              commercialListingAgent.map(
                ({
                  id,
                  image,
                  managerName,
                  managerTitle,
                  managerOfficePhone,
                  managerMobilePhone,
                  managerEmail,
                }) => (
                  <div className="flex consultant" key={id}>
                    {image && <Img fluid={image.fluid} />}
                    <div className="consultant-info">
                      <h3>{managerName}</h3>
                      <a href={`tel:${managerMobilePhone}`}>
                        {managerMobilePhone}
                      </a>
                      <a href={`mailto:${managerEmail}`}>{managerEmail}</a>
                    </div>
                  </div>
                )
              )}
          </div>
        </Container>
      </Section>
      <Section grey>
        <Container>
          <h2>Contact</h2>
          <ContactForm title={commercialListingName} name="Commercial Form" />
        </Container>
      </Section>
    </Layout>
  )
}

export default CommercialTemplate

export const pageQuery = graphql`
  query pageQueryCommercial($id: String) {
    contentfulListingCommercial(id: { eq: $id }) {
      id
      commercialListingName #title
      slug
      # Photos
      commercialListingphotos {
        id
        fluid(maxWidth: 600, maxHeight: 400, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
        }
      }
      commercialListingphotosModal: commercialListingphotos {
        id
        fluid(maxWidth: 1920, maxHeight: 1280, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
        }
      }
      photosInteriors {
        id
        fluid(maxWidth: 600, maxHeight: 400, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
        }
      }
      photosInteriorsModal: photosInteriors {
        id
        fluid(maxWidth: 1920, maxHeight: 1280, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
        }
      }
      commercialListingSecteur #sector
      commercialListingSize #superficie
      commercialListingAvailability #date
      commercialListingStories #etage
      # Characteristics
      commercialSpecs
      # A proximite
      commercialListingNearby
      # Plans
      commercialListinglocauxLouer {
        id
        fluid(maxWidth: 1920, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
        }
      }
      # Map
      googleMapCommercial {
        lon
        lat
      }
      # Managers
      commercialListingAgent {
        id
        image {
          fluid(maxWidth: 200, maxHeight: 200, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid
          }
        }
        managerName
        managerTitle
        managerOfficePhone
        managerMobilePhone
        managerEmail
      }
    }
  }
`
